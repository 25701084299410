<template>
    <div>
        <el-card class="management-report-card-box">
            <el-form>
                <div class="flex flex-row justify-between items-center mb-2">
                    <h2 class="text-base font-semibold mb-2">{{ displayNoteNumber }} {{ title }}</h2>
                    <div class="flex items-center space-x-2">
                        <el-button title="Flytta upp" icon="el-icon-arrow-up" circle @click="moveCardUp()" />
                        <el-button title="Flytta ned" icon="el-icon-arrow-down" circle @click="moveCardDown()" />
                        <el-switch v-model="localToggleValue" active-color="#48c78e" inactive-color="#D1D5DB" @change="onToggleChange" />
                    </div>
                </div>
                <div v-if="toggleValue" v-loading="$waiting.is('loading-placedSecurities')" class="flex flex-col">
                    <el-input placeholder="Beskrivning" type="textarea" :disabled="inputDisabled" v-model="localInputText" class="break-normal custom-textarea p-2" />
                    <el-table :data="placedSecuritiesData.placedSecuritiesTableRows">
                        <el-table-column label="Text" width="400">
                            <template slot-scope="{ row }">
                                <el-input v-model="row.description" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                        <el-table-column label="Nuvarande år">
                            <template slot-scope="{ row }">
                                <el-input v-model="row.currentYear" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                        <el-table-column label="Föregående år">
                            <template slot-scope="{ row }">
                                <el-input v-model="row.previousYear" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="{ $index, store, row }">
                                <template v-if="store.states.data.length !== 1">
                                    <el-button plain size="mini" @click="deleteRowTable(row)" :disabled="inputDisabled">
                                        <i class="fa-solid fa-minus" />
                                    </el-button>
                                </template>
                                <template v-if="$index === store.states.data.length - 1">
                                    <el-button plain size="mini" @click="addRowTable" :disabled="inputDisabled">
                                        <i class="fa-solid fa-plus" />
                                    </el-button>
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="flex flex-row justify-between items-end pt-4">
                        <NoteNumberOptions
                            :notes-number="localNum"
                            @update:notesNumber="localNum = $event"
                            :result-note-numbers-list="localResultNoteNumbersList"
                            @update:resultNoteNumbersList="localResultNoteNumbersList = $event"
                            :selected-result-note-numbers="localSelectedResultNoteNumbers"
                            @update:selectedResultNoteNumbers="localSelectedResultNoteNumbers = $event"
                            :balance-note-numbers-list="localBalanceNoteNumbersList"
                            @update:balanceNoteNumbersList="localBalanceNoteNumbersList = $event"
                            :selected-balance-note-numbers="localSelectedBalanceNoteNumbers"
                            @update:selectedBalanceNoteNumbers="localSelectedBalanceNoteNumbers = $event"
                            :input-disabled="inputDisabled"
                        />
                        <CardEditButtons :toggle-value="toggleValue" :show-edit-button="showEditButton" :toggle-edit="toggleEdit" :close-modal="closeModal" :save-data="saveData" />
                    </div>
                </div>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import { Switch } from "element-ui";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        tooltipInfo: {
            type: String,
            default: "",
        },
        toggleValue: {
            type: Boolean,
            default: true,
        },
        placedSecuritiesData: {
            type: Object,
            default: () => ({}),
        },
        notesData: {
            type: Object,
            default: () => ({}),
        },
        indexNumber: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            selectedYear: null,
            financialYearId: null,
            annualReportId: undefined,
            inputDisabled: true,
            showEditButton: true,
            localToggleValue: this.toggleValue,
            localInputText: this.placedSecuritiesData.text,
            initialInputText: "",
            isInitialInputTextSet: false,
            localNum: this.placedSecuritiesData.notesNumber,
            localSelectedResultNoteNumbers: this.notesData.selectedResultNoteNumbers,
            localResultNoteNumbersList: [],
            localSelectedBalanceNoteNumbers: this.notesData.selectedBalanceNoteNumbers,
            localBalanceNoteNumbersList: [],
        };
    },
    components: {
        "el-switch": Switch,
        CardEditButtons: () => import("../../managementStatement/components/CardEditButtons.vue"),
        NoteNumberOptions: () => import("./NoteNumberOptions.vue"),
    },
    watch: {
        toggleValue(newVal) {
            this.localToggleValue = newVal;
        },
    },
    async created() {
        await this.getAllSelectedList();
    },
    methods: {
        async getAllSelectedList() {
            this.localResultNoteNumbersList = this.notesData.incomeStatementNotesNumbers.map(item => ({
                ...item,
                disabled: false,
            }));
            this.getSelectedList(this.localResultNoteNumbersList, this.localSelectedResultNoteNumbers);

            this.localBalanceNoteNumbersList = this.notesData.balanceNotesNumbers.map(item => ({
                ...item,
                disabled: false,
            }));
            this.getSelectedList(this.localBalanceNoteNumbersList, this.localSelectedBalanceNoteNumbers);
        },
        getSelectedList(list, selectedList) {
            list.forEach(row => {
                this.disableRows(row);
                if (this.localNum === row.notesNumber && !selectedList.includes(row.value)) {
                    selectedList.push(row.value);
                }
            });
        },
        toggleEdit() {
            this.inputDisabled = !this.inputDisabled;
            this.showEditButton = !this.showEditButton;
        },
        onToggleChange(newVal) {
            const eventData = {
                source: 2,
                value: newVal,
                index: this.indexNumber,
            };
            this.$emit("toggle-change", eventData);
        },
        async saveData() {
            this.placedSecuritiesData.text = this.localInputText;
            this.placedSecuritiesData.notesNumber = this.localNum;
            this.isInitialInputTextSet = false;

            this.notesData.selectedResultNoteNumbers = this.localSelectedResultNoteNumbers.flat();
            this.notesData.selectedBalanceNoteNumbers = this.localSelectedBalanceNoteNumbers.flat();
            this.updateNoteNumbers();
            this.$emit("saveData");
            this.toggleEdit();
            this.$notify.success({ title: `Sparat "${this.title}"` });
        },
        updateNoteNumbers() {
            this.localResultNoteNumbersList.forEach(item => {
                if (this.localSelectedResultNoteNumbers.includes(item.value)) {
                    item.notesNumber = this.localNum;
                }
            });
            const newSelectedResultNoteNumbers = this.localResultNoteNumbersList.map(item => ({
                label: item.label,
                notesNumber: item.notesNumber,
                value: item.value,
            }));
            this.notesData.incomeStatementNotesNumbers = newSelectedResultNoteNumbers;

            this.localBalanceNoteNumbersList.forEach(item => {
                if (this.localSelectedBalanceNoteNumbers.includes(item.value)) {
                    item.notesNumber = this.localNum;
                }
            });
            const newSelectedBalanceNoteNumbers = this.localBalanceNoteNumbersList.map(item => ({
                label: item.label,
                notesNumber: item.notesNumber,
                value: item.value,
            }));
            this.notesData.balanceNotesNumbers = newSelectedBalanceNoteNumbers;
        },
        closeModal() {
            this.$emit("close");
            const load = "loading-placedSecurities";
            this.$emit("close", { load: load });
            this.toggleEdit();
        },
        addRowTable() {
            const newObj = {
                description: "",
                currentYear: "",
                previousYear: "",
            };
            this.placedSecuritiesData.placedSecuritiesTableRows.push(newObj);
        },
        deleteRowTable(row) {
            const placedSecuritiesTableRows = this.placedSecuritiesData.placedSecuritiesTableRows;
            if (placedSecuritiesTableRows.length > 0) {
                const index = placedSecuritiesTableRows.indexOf(row);
                if (index > -1) {
                    placedSecuritiesTableRows.splice(index, 1);
                }
            }
        },
        disableRows(row) {
            const isInCard = row.notesNumber === this.localNum || row.notesNumber === 0;
            if (isInCard) {
                row.disabled = false;
            } else {
                row.disabled = true;
            }
        },
        async moveCardUp() {
            this.$emit("move-up");
            this.$emit("saveData");
            await this.getAllSelectedList();
        },
        async moveCardDown() {
            this.$emit("move-down");
            this.$emit("saveData");
            await this.getAllSelectedList();
        },
    },

    computed: {
        displayNoteNumber() {
            return this.localToggleValue ? `Not ${this.localNum}` : "";
        },
    },
};
</script>
